<template lang="pug">
.brand-wrapper.brand-campaigns.edit-segment
  .container-fluid
    transition-group(name="fade")
      editable-title(
        h1
        v-if="segment"
        key="name"
        :title="segmentName"
        @renameTitle="renameSegment"
      )
      Targeting.p-0(
        v-if="segment"
        :show="true"
        @change="segmentRules = $event"
        :rules="segmentRules"
        key="targeting"
        editMode="segmentSettings"
      )
    .d-flex.justify-content-center(v-if="segment" key="buttons")
      om-button.mr-3(@click="$router.go(-1)") {{ $t('cancel') }}
      om-button(primary @click="saveRules" :disabled="!segmentRules.length") {{ $t('saveSegment') }}
</template>

<script>
  import EditableTitle from '@/components/EditableTitle.vue';
  import SEGMENTS from '@/graphql/Segments.gql';
  import Targeting from '@/components/Segments/Targeting.vue';
  import { required } from 'vuelidate/lib/validators';
  import UPSERT_SEGMENT from '@/graphql/UpsertSegment.gql';

  export default {
    components: { EditableTitle, Targeting },
    data: () => ({
      segments: [],
      segmentName: '',
      segmentRules: [],
    }),
    validations: {
      segmentName: {
        required,
        duplicate(value, vm) {
          return !vm.names.includes(value);
        },
      },
    },
    apollo: {
      segments: {
        query: SEGMENTS,
        variables: { type: 'userdefined' },
      },
    },
    computed: {
      id() {
        return this.$route.params.id;
      },
      segment() {
        return this.segments.find(({ _id }) => _id === this.id);
      },
      currentName() {
        return this.segment.name[this.$i18n.locale];
      },
      names() {
        return this.segments.map(({ name }) => [name.en, name.hu]).flat();
      },
    },
    watch: {
      segment: {
        deep: true,
        handler() {
          this.initSegmentName();
          this.initSegmentRules();
        },
      },
    },
    mounted() {
      if (this.segment) {
        this.initSegmentName();
      }
    },
    methods: {
      async renameSegment(name) {
        this.segmentName = name;
        this.$v.$touch();

        if (this.$v.$error) {
          this.showNotification(false, this.$t('notifications.segments.nameExists'));
          this.initSegmentName();
        } else {
          const name = { en: this.segmentName, hu: this.segmentName };
          const success = await this.updateSegment({ name });
          this.showNotification(success);
          this.segment.name = name;
        }
      },
      initSegmentName() {
        this.segmentName = this.segment.name[this.$i18n.locale];
      },
      initSegmentRules() {
        this.segmentRules = this.segment.frontendRules;
      },
      async saveRules() {
        const success = await this.updateSegment({ frontendRules: this.segmentRules });
        this.showNotification(success);
        if (!success) {
          this.initSegmentRules();
          return;
        }
        this.segment.frontendRules = this.segmentRules;
        this.$router.push({ name: 'subscribers', query: { tab: 'segments' } });
      },
      showNotification(isSuccess, errorText = this.$t('notifications.saveError')) {
        console.log('show noti', isSuccess, errorText);
        const type = isSuccess ? 'success' : 'error';
        const text = isSuccess ? this.$t('notifications.saveSuccess') : errorText;
        this.$notify({ type, text });
      },
      async updateSegment(modifications) {
        const segment = {
          ...this.segment,
          ...modifications,
          createdAt: undefined,
          updatedAt: undefined,
        };

        const {
          data: { result },
        } = await this.$apollo.mutate({
          mutation: UPSERT_SEGMENT,
          variables: { segment },
        });

        return result;
      },
    },
  };
</script>

<style lang="sass">
  .edit-segment
    .campaign-settings-box
      padding: 0
</style>
